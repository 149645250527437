<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="약품 목록"
        tableId="grid"
        :columns="grid.columns"
        :data="grid.data"
        :isExcelDown="false"
        :isExpan="false"
        :isFullScreen="false"
        :columnSetting="false"
        gridHeight="500px"
        rowKey="medicineId"
        :selection="popupParam.type"
        :editable="editable"
        @rowDblclick="rowDblclick"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="검색" icon="search"  @btnClicked="getList"/>
            <c-btn label="선택" icon="check" @btnClicked="select" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'medicine-manage-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:100px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'medicineName',
            field: 'medicineName',
            label: '약품',
            align: 'center',
            style: 'width:200px',
            sortable: false,
            setHeader: true,
          },
          {
            name: 'medicineStockCount',
            field: 'medicineStockCount',
            label: '적정 재고량',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'medicineRecentCount',
            field: 'medicineRecentCount',
            label: '현재 재고량',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'unitName',
            field: 'unitName',
            label: '단위',
            style: 'width:100px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'howToUse',
            field: 'howToUse',
            label: '투약방법',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'efficacy',
            field: 'efficacy',
            label: '효능',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'sideEffect',
            field: 'sideEffect',
            label: '부작용',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        useFlag: 'Y',
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      this.listUrl = selectConfig.hea.medicine.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },  
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '약품을 먼저 지정하세요.', // 약품을 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
